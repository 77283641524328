import { IAuth } from 'interfaces/responses';
import { baseApi } from 'store/services/base';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IAuth, { userName: string; password: string }>({
      query: (credentials) => ({
        url: 'Auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    refreshToken: builder.mutation<string, { refreshToken: string }>({
      query: ({ refreshToken }) => ({
        url: `Auth/RefreshToken?refreshToken=${refreshToken}`,
        method: 'POST',
      }),
    }),
    getUserInfo: builder.query<IAuth, void>({
      query: () => ({
        url: 'Auth/userinfo',
        method: 'POST',
        body: {},
        keepUnusedDataFor: 600,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
} = authApi;
