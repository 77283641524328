import { lazy, Suspense } from 'react';

import { Redirect, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Login } from 'modules/auth';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const SurveyForm = lazy(() => import('modules/surveys/survey-form'));
const SystemAdminRoute = lazy(() => import('./system-admin'));
const SupportAdminRoute = lazy(() => import('./support-admin'));

const PortfolioCustomerRoute = lazy(() => import('./portfolio-customer'));
const PortfolioUserRoute = lazy(() => import('./portfolio-user'));

import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './hoc/PrivateRoute';
import PublicRoute from './hoc/PublicRoute';

import { USER_ROLES } from 'modules/common/constants/userRoles';
import { useTypedSelector } from 'store/hooks';
import { FullScreenLoader } from 'modules/common/components/FullScreenLoader';

const silentEndpoints = [
  'getShiftsList',
  'getSitesList',
  'getUsersList',
  'getCleaningPointsList',
  'getAuditScorecardsList',
  'getAuditTemplatesList',
  'getContractsList',
  'getClientPDFReport',
  'refreshToken',
  'validatePhoneNumber',
];

const RootRouters = () => {
  const isQueryLoading = useTypedSelector((state) =>
    Object.values(state.baseApi.queries).some((entry) => {
      return entry && entry.status === 'pending' && !silentEndpoints.includes(entry.endpointName);
    }),
  );

  const isMutationLoading = useTypedSelector((state) =>
    Object.values(state.baseApi.mutations).some((entry) => {
      return entry && entry.status === 'pending' && !silentEndpoints.includes(entry.endpointName);
    }),
  );

  return (
    <>
      <Suspense fallback={<FullScreenLoader open />}>
        <Switch>
          <PublicRoute exact path='/login' component={Login} />
          <PublicRoute
            exact
            path='/survey-form'
            render={() => (
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
              >
                <SurveyForm />
              </GoogleReCaptchaProvider>
            )}
          />

          <PrivateRoute
            path='/system-admin'
            component={SystemAdminRoute}
            allowedRole={USER_ROLES.SYSTEM_ADMIN}
          />
          <PrivateRoute
            path='/support-admin'
            component={SupportAdminRoute}
            allowedRole={USER_ROLES.SUPPORT_ADMIN}
          />

          <PrivateRoute
            path='/portfolio-customer'
            component={PortfolioCustomerRoute}
            allowedRole={USER_ROLES.PORTFOLIO_CUSTOMER}
          />
          <PrivateRoute
            path='/portfolio-user'
            component={PortfolioUserRoute}
            allowedRole={USER_ROLES.PORTFOLIO_USER}
          />

          <Redirect from='/' to='/login' />
        </Switch>
      </Suspense>
      <FullScreenLoader open={isQueryLoading || isMutationLoading} />
      <ToastContainer
        position='bottom-right'
        autoClose={2500}
        newestOnTop={false}
        rtl={false}
        closeButton={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        hideProgressBar
      />
    </>
  );
};

export default withRouter(RootRouters);
